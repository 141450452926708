export default {
	created(){
		
	},
	methods: {
	},
	computed:{
		getLink(){
			let langue = this.$store.state.ui.langue
			//Langue sur 2 caractères
			if(langue.length > 2){
				langue = langue.substring(0, 2)
			}
			return window.location.origin + '/' + langue +'/experimentation/#/'
		}
	}
}