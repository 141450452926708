import { render, staticRenderFns } from "./menu_experimentation.vue?vue&type=template&id=3998566f&scoped=true"
import script from "./menu_experimentation.js?vue&type=script&lang=js&external"
export * from "./menu_experimentation.js?vue&type=script&lang=js&external"
import style0 from "./menu_experimentation.vue?vue&type=style&index=0&id=3998566f&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3998566f",
  null
  
)

export default component.exports