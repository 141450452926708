import _ from 'lodash'

import Menu_bienvenue from '../bienvenue/menu_bienvenue.vue'
import Menu_edition_entreprise from './menu_edition_entreprise.vue'
import Menu_edition_matieres from './menu_edition_matieres.vue'
import Menu_experimentation from '../experimentation/menu_experimentation.vue'

export default {
	data() {
		return {
		}
	},
	created(){
	},
	methods: {
		close_filter(){
			this.$store.commit('UI_SET_CONTENT', {content: 'result'});
		}
	},
	computed:{
		
	},
	components: {
		Menu_bienvenue,
		Menu_edition_entreprise,
		Menu_edition_matieres,
		Menu_experimentation
  	}
}